import React from "react";
import { Helmet } from "react-helmet";

const ProductEngineering = () => {
  return (
    <>
      {" "}
      <Helmet>
        <meta charSet="utf-8" />
        <title>Product Engineering</title>
        <link rel="canonical" href="/product-engineering" />
      </Helmet>
      <div className="spinner-loader bg-primary text-white">
        <div className="spinner-border" role="status"></div>
      </div>
      <main>
        <section className="position-relative bg-gradient-tint">
          <div className="container position-relative pt-14 pb-9">
            <div className="row pt-lg-9 pb-lg-4">
              <div className="col-lg-10 mx-auto text-center">
                <h1 className="display-4 mb-3">Product Engineering</h1>
                <p className="lead mb-0">
                  Transforming ideas into impactful, future-ready solutions with
                  end-to-end product engineering excellence.
                </p>
              </div>
            </div>
            {/* <!--/.row--> */}
          </div>
          {/* <!--/.content--> */}
        </section>
        <section className="position-relative">
          <div className="container pt-6 pb-7 pb-lg-9 position-relative">
            <div className="row pt-9 pt-lg-6  align-items-center">
              <p>
                At GPracta, we bring innovation to life through our
                comprehensive product engineering services. We specialize in
                crafting custom software solutions that empower businesses
                across North America and beyond. Our deep expertise spans a wide
                range of industries, allowing us to deliver robust, scalable,
                and future-ready products that align with your strategic goals.
              </p>
            </div>
          </div>
          {/* <!--Hero image--> */}
        </section>
        {/* <!--begin:feature image section--> */}
        <section className="position-relative">
          <div className="container position-relative py-9 py-lg-8">
            <div className="row justify-content-between align-items-center">
              <div
                className="col-lg-6 mb-5 mb-lg-0 order-lg-last"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                <div className="position-relative p-2">
                  {/* <!--background-parallax-shape--> */}
                  <div
                    className="rellax bg-warning width-9x height-9x rounded-circle position-absolute end-0 top-0"
                    data-rellax-percentage="0.95"
                    data-rellax-speed="2"
                  ></div>
                  {/* <!--background-parallax-shape--> */}
                  <div
                    className="rellax bg-danger width-5x height-5x rounded-circle position-absolute bottom-0 mb-5 start-0"
                    data-rellax-percentage="0.1"
                    data-rellax-speed="-1"
                  ></div>

                  {/* <!--Shape Image with mask--> */}
                  <div className="bg-mask">
                    <img
                      src="assets/img/960x900/exec-search.png"
                      className="mask-squircle mask-image"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div
                className="col-lg-5 order-lg-1 position-relative"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                {/* <!--Heading--> */}
                <h2 className="mb-4 display-6">
                  Let us build{" "}
                  <span className="text-gradient">your vision</span> into
                  reality!
                </h2>

                {/* <!--Feature-card--> */}
                <div className="d-flex">
                  <div className="me-3 mt-1">
                    <i className="bx bx-check-circle fs-4"></i>
                  </div>
                  <div>
                    <p className="mb-0">
                      <span className="fw-bold">New Product Development:</span>{" "}
                      We partner with you to transform your vision into reality.
                      From concept to launch, our expert team of developers,
                      designers, and engineers work collaboratively to build
                      innovative products that set new industry standards.
                    </p>
                  </div>
                </div>
                {/* <!--Feature-card--> */}
                <div className="d-flex">
                  <div className="me-3 mt-1">
                    <i className="bx bx-check-circle fs-4"></i>
                  </div>
                  <div>
                    <p className="mb-0">
                      <span className="fw-bold">Technology Modernization:</span>{" "}
                      Our relationships with our customers is not only a
                      business relationship but we are trusted partner. Engaging
                      customers at the highest levels provides our consultants
                      with insight on their leadership and organizational
                      practices.
                    </p>
                  </div>
                </div>
                {/* <!--Feature-card--> */}
                <div className="d-flex">
                  <div className="me-3 mt-1">
                    <i className="bx bx-check-circle fs-4"></i>
                  </div>
                  <div>
                    <p className="mb-0">
                      <span className="fw-bold">Data Engineering:</span> Harness
                      the power of data to drive smarter decisions. Our data
                      engineering services include data architecture design,
                      data pipeline development, and big data analytics,
                      ensuring your data is clean, secure, and actionable.
                    </p>
                  </div>
                </div>
                {/* <!--Feature-card--> */}
                <div className="d-flex">
                  <div className="me-3 mt-1">
                    <i className="bx bx-check-circle fs-4"></i>
                  </div>
                  <div>
                    <p className="mb-0">
                      <span className="fw-bold">Quality Assurance:</span>{" "}
                      Quality is non-negotiable. Our comprehensive QA services
                      include manual and automated testing, performance testing,
                      and security assessments, ensuring your products meet the
                      highest standards before they reach your customers.
                    </p>
                  </div>
                </div>
                {/* <!--Feature-card--> */}
                <div className="d-flex">
                  <div className="me-3 mt-1">
                    <i className="bx bx-check-circle fs-4"></i>
                  </div>
                  <div>
                    <p className="mb-0">
                      <span className="fw-bold">
                        Customer Success & Account Management:
                      </span>{" "}
                      We believe that true success goes beyond product delivery.
                      Our Customer Success and Account Management teams work
                      closely with you to ensure that your product not only
                      meets your expectations but also evolves with your
                      business needs. From seamless product rollouts to ongoing
                      maintenance and support, we proactively manage your
                      account, providing strategic insights and personalized
                      solutions that drive long-term value and customer
                      satisfaction.
                    </p>
                  </div>
                </div>
                {/* <!--Feature-card--> */}
                <div className="d-flex">
                  <div className="me-3 mt-1">
                    <i className="bx bx-check-circle fs-4"></i>
                  </div>
                  <div>
                    <p className="mb-0">
                      <span className="fw-bold">Digital Marketing:</span> Your
                      product deserves the spotlight. Our digital marketing
                      experts help you reach your target audience through
                      strategic campaigns, SEO, social media marketing, and
                      content creation, ensuring your product’s success in the
                      marketplace.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!--begin:Call to action section--> */}
        <section className="position-relative bg-body-tertiary border-top overflow-hidden">
          <div className="container py-9 py-lg-11 position-relative">
            <div className="row pb-7 position-relative">
              <div className="col-12 col-md-10 col-lg-12 mx-auto text-center">
                <h2 className="mb-4 display-5 fw-lighter" data-aos="fade-down">
                  Why Choose{" "}
                  <span className="fw-bolder d-inline-block pb-1 position-relative text-primary">
                    GPracta
                  </span>
                  ?
                </h2>
                <p
                  className="mb-5 px-lg-7 lead"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  At GPracta, we blend innovation with precision to deliver
                  tailored product engineering solutions that drive business
                  success. With a deep understanding of evolving technologies
                  and industry trends, our team excels in turning your vision
                  into robust, scalable products. From new product development
                  to technology modernization, we prioritize quality,
                  efficiency, and long-term value, ensuring your products stay
                  competitive in a rapidly changing market. Partner with GPracta
                  for expert engineering that empowers your business to thrive.
                </p>
                <p
                  className="mb-5 px-lg-7 lead"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  Contact us today to learn more.
                </p>
                <div data-aos="fade-up" data-aos-delay="150">
                  <a
                    href="#!"
                    className="btn btn-outline-primary btn-hover-arrow btn-lg"
                  >
                    <span>Click to Contact Us</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default ProductEngineering;
